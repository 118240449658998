import React from "react"
import Layout from "../components/layout/Layout"
import AllCategories from "../components/nationalpark/AllCategories"
import MetaData from "../components/seo/MetaData"
import OrganizationStructuredData from "../components/seo/OrganizationStructuredData"
import SmallContainer from "../components/layout/SmallContainer"
import ParkSectionText from "../components/nationalpark/ParkSectionText"

interface Props {
  location: any
}

export default function ParkTypesPage({ location }: Props) {
  return (
    <Layout>
      <MetaData
        title="National park types"
        description="See a list of all the national parks with different characteristics. Complete with a map and ideas for sights, hiking trails and more."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <SmallContainer center={true}>
        <div className={`container mx-auto md:px-2 px-2 text-center`}>
          <h1 className="mt-6 md:mt-8 mb-0">Choose a park characteristic</h1>
          <p className="mt-2">
            The national parks across the world are very different from each
            other. Here you will be able to see the different types of parks and
            find one with your favourite characteristics.
          </p>
        </div>
      </SmallContainer>
      <AllCategories />
      <ParkSectionText />
    </Layout>
  )
}
