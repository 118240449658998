import { Link } from "gatsby"
import React from "react"
import WideContainer from "../layout/WideContainer"
import { StaticImage } from "gatsby-plugin-image"
import Routes from "../../constants/Routes"

export default function AllCategories() {
  const rightPadding = "md:ml-0"
  const middlePadding = "md:px-0"
  const leftPadding = "md:mr-0"

  return (
    <div className="px-4 pt-0">
      <div className="pt-0 pb-6">
        <WideContainer center={false}>
          <div className="grid grid-cols-1 md:grid-cols-3 py-4">
            <div className={leftPadding}>
              <Link to={Routes.TAG_ALPINE} title={`Alpine National Parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/sidebar/mountain-sidebar.jpg"
                      alt="Alpine National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Alpine parks</h3>
                      <span className="absolute text-sm text-light ml-4 mt-14">
                        Explore the highest peaks
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={middlePadding}>
              <Link to={Routes.TAG_CLIFFS} title={`Cliff National Parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/sidebar/cliff-sidebar.jpg"
                      alt="Cliff National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">
                        Parks with cliffs
                      </h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Explore rugged landscapes
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={rightPadding}>
              <Link to={Routes.TAG_FOREST} title={`Forest National Parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/explore/explore-forest.jpg"
                      alt="Forest National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Forest parks</h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Explore plant ecosystems
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={rightPadding}>
              <Link to={Routes.TAG_MOUNTAINS} title={`Mountain National Parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/explore/mountains.jpg"
                      alt={`Mountain National Parks`}
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Mountain parks</h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Explore the heights
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={middlePadding}>
              <Link to={Routes.TAG_LAKES} title={`National parks with lakes`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/explore/lakes.jpg"
                      alt="Lake National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Lake parks</h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Explore great lakes
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={leftPadding}>
              <Link to={Routes.TAG_FJORD} title={`Fjord National Parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/explore/fjord.jpg"
                      alt="Fjord National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Fjord parks</h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Explore vast waters
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={rightPadding}>
              <Link to={Routes.TAG_SMALL} title={`Small national parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/explore/small-parks.jpg"
                      alt="Small National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Small parks</h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Easy to explore
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={middlePadding}>
              <Link to={Routes.TAG_COAST} title={`Coast national parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/explore/coast.jpg"
                      alt="Coast National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Coast parks</h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Experience the sea
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={leftPadding}>
              <Link to={Routes.TAG_BOG} title={`Bog national parks`}>
                <div className="md:mr-6 mt-4 mr-0">
                  <div
                    className="img-hover-zoom rounded"
                    style={{ display: "grid" }}
                  >
                    <StaticImage
                      src="../../images/explore/bog.jpg"
                      alt="Bog National Parks"
                      placeholder="blurred"
                      className="w-full object-cover"
                      style={{
                        gridArea: "1/1",
                      }}
                    />
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                    >
                      <h3 className="ml-4 mb-4 text-white">Bog parks</h3>
                      <span className="absolute text-sm text-lightest ml-4 mt-14">
                        Explore the wetlands
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </WideContainer>
      </div>
    </div>
  )
}
